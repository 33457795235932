import React, { useState } from "react";
import styles from "./BetaAndroid.module.css";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import useSound from "use-sound";
import clickSound from "./../../assets/sounds/button_nav.mp3";
import { set } from "rsuite/esm/internals/utils/date";

function BetaAndroid() {
  const [play] = useSound(clickSound, { volume: 0.5 });

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Expression régulière pour le format de l'email
    const emailPattern = /^[a-zA-Z0-9._%+-]+@etu\.univ-poitiers\.fr$/;

    if (!emailPattern.test(email)) {
      setError(
        "Veuillez entrer une adresse e-mail valide au format @etu.univ-poitiers.fr"
      );
      setSuccess("");
    } else {
      setError("");
      // Envoyer les données du formulaire
      try {
        const response = await fetch(
          "https://dev.libellule.app/user/firstlogin",
          {
            method: "POST",
            body: JSON.stringify({
              email_edu: email,
              device: "android",
            }),
            headers: { "Content-Type": "application/json" },
          }
        );
        if (response.status === 400) {
          setError(
            "Les identifiants ont déjà été envoyés. En cas de problème, veuillez nous contacter par mail."
          );
          setSuccess("");
          throw new Error(
            "Vous avez déjà rejoint la bêta fermée de Libellule."
          );
        }

        if (response.status === 404) {
          setError(
            "L'adresse mail renseignée n'existe pas. Si vous pensez qu'il s'agit d'une erreur, veuillez nous contacter par mail."
          );
          setSuccess("");
          throw new Error(
            "L'adresse mail renseignée n'est pas une adresse mail étudiante de l'Université de Poitiers."
          );
        }

        if (!response.ok) {
          throw new Error("Erreur lors de l'envoi des données");
        } else {
          setSuccess(
            "Parfait ! Un mail vous a été envoyé avec les étapes à réaliser pour rejoindre la bêta fermée de Libellule."
          );
        }

        const data = await response.json();
      } catch (error) {
        console.error("Erreur de requête :", error);
        setSuccess("");
      }
    }
  };

  return (
    <div className={styles.betaandroid}>
      <div className={styles.betaandroidContainer}>
        <div className={styles.betaandroidText}>
          <div className={styles.betaandroidTitle}>
            <h2>Incription à la bêta fermée de Libellule sur Android</h2>
          </div>
          <div className={styles.betaandroidDescription}>
            <p>
              Nous sommes ravis de vous offrir l'opportunité de participer à la
              bêta fermée de Libellule. En rejoignant cette phase de test, vous
              contribuez directement à l'amélioration de notre application. Nous
              travaillons activement à corriger les bugs et à intégrer vos
              retours pour optimiser votre expérience.
            </p>
            <p>
              Si vous rencontrez des problèmes dans les étapes ou que vous ne
              recevez pas le mail, vous pouvez nous contactez depuis la page{" "}
              <NavLink
                className={styles.betaandroidDescriptionLink}
                to="/contact"
              >
                contact
              </NavLink>
            </p>
          </div>
        </div>
        <div className={styles.betaandroidFormContainer}>
          <p className={styles.betaandroidFormDescription}>
            En renseignant votre <span>adresse mail étudiante</span> ci-dessous,
            vous recevrez un mail vous expliquant les{" "}
            <span>étapes à réaliser</span> pour rejoindre la bêta fermée.
          </p>
          <div className={styles.betaandroidForm}>
            <form
              onSubmit={handleSubmit}
              className={styles.betaandroidFormContent}
            >
              <input
                className={styles.closebetaFormInput}
                type="email"
                name="email"
                placeholder="Entrer l'adresse mail étudiante"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <motion.div
                className={styles.formSendButton}
                whileHover={{
                  scale: 1.03,
                  transition: { delay: 0, duration: 0.3 },
                }}
                whileTap={{
                  scale: 0.97,
                  transition: { delay: 0, duration: 0.3 },
                }}
                animate={{
                  scale: 1,
                  transition: { delay: 0, duration: 0.3 },
                }}
                onMouseEnter={play}
              >
                <input
                  type="submit"
                  className={styles.closebetaFormButton}
                  value="Rejoindre la bêta fermée"
                />
              </motion.div>
            </form>
            {error && (
              <div className={styles.error}>
                <p>{error}</p>
              </div>
            )}
            {success && (
              <div className={styles.success}>
                <p>{success}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BetaAndroid;
