import React from "react";
import styles from "./ButtonNavigation.module.css";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import useSound from "use-sound";
import clickSound from "./../../../../../assets/sounds/button_nav.mp3";

function Button({ name, link }) {
  const [play] = useSound(clickSound, { volume: 0.5 });

  return (
    <NavLink to={link} className={styles.buttonLink}>
      <motion.div
        className={styles.button}
        whileHover={{
          scale: 1.03,
          transition: { delay: 0, duration: 0.3 }, // Ajoutez une durée pour rendre le mouvement fluide
        }}
        whileTap={{
          scale: 0.97,
          transition: { delay: 0, duration: 0.3 },
        }}
        animate={{
          scale: 1,
          transition: { delay: 0, duration: 0.3 }, // Assurez-vous que l'animation de retour est également sans délai
        }}
        onMouseEnter={play}
      >
        <p>{name}</p>
      </motion.div>
    </NavLink>
  );
}

export default Button;
