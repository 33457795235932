import React from "react";
import styles from "./TaskItem.module.css";

function TaskItem({ name }) {
  return (
    <div className={styles.taskItem}>
      <div className={styles.taskItemIcon}>
        <i className="fi fi-rr-check-circle"></i>
      </div>
      <div className={styles.taskItemText}>
        <p>{name}</p>
      </div>
    </div>
  );
}

export default TaskItem;
