import React from "react";
import styles from "./HeaderDesktop.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useWindowScroll } from "@uidotdev/usehooks";
import useSound from 'use-sound';
import clickSound from "./../../../../assets/sounds/nav.mp3";

function HeaderDesktop({pagesWithoutDynamicNavbar}) {
  const [{ x, y }] = useWindowScroll();

  const location = useLocation();

  const [play] = useSound(clickSound, { volume: 0.75 });

  return (
    <nav className={(y < 100 && !pagesWithoutDynamicNavbar.includes(location.pathname) ? styles.navbarTop : styles.navbar)}>
      <div className={styles.container}>
        <NavLink className={styles.navbarBrand} to="/" onClick={play}>
          <img 
            src="/assets/img/libellule_logo.svg"
            alt=""
          />
          <h1>Libellule</h1>
        </NavLink>
        <motion.ul className={styles.navbarNav}>
          <motion.li className={styles.navbarItem} initial={{y: 20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ease: "easeOut", duration: 0.3, delay: 0 }} onClick={play}>
            <NavLink className={({ isActive }) => (isActive ? styles.navbarLinkActive : styles.navbarLinkInactive)} to="/">
              Accueil
            </NavLink>
          </motion.li>
          <motion.li className={styles.navbarItem} initial={{y: 20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ease: "easeOut", duration: 0.3, delay: 0.1 }} onClick={play}>
            <NavLink className={({ isActive }) => (isActive ? styles.navbarLinkActive : styles.navbarLinkInactive)} to="/features">
              Fonctionnalités
            </NavLink>
          </motion.li>
          <motion.li className={styles.navbarItem} initial={{y: 20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ease: "easeOut", duration: 0.3, delay: 0.2 }} onClick={play}>
            <NavLink className={({ isActive }) => (isActive ? styles.navbarLinkActive : styles.navbarLinkInactive)} to="/contact">
              Contact
            </NavLink>
          </motion.li>
        </motion.ul>
        
      </div>
    </nav>
  );
}

export default HeaderDesktop;
