import React from "react";
import styles from "./MailValidation.module.css";
import { MailCheck } from "lucide-react";

import Button from "../others/inputs/buttons/ButtonNavigation/ButtonNavigation";

function MailValidation() {
  return (
    <section className={styles.mailValidation}>
      <div className={styles.mailValidationContainer}>
        <MailCheck color="var(--blue-700)" size={60} />
        <div className={styles.mailValidationText}>
          <p>Votre mail a bien été validé !</p>
          <p>
            Vous pouvez désormais vous connecter à l'application Libellule en
            utilisant votre adresse mail et votre mot de passe.
          </p>
        </div>
        <Button name="Découvrir le site de Libellule" link="/" />
      </div>
    </section>
  );
}

export default MailValidation;
