import React from "react";
import styles from "./Contact.module.css";

// import components
import Button from "../inputs/buttons/ButtonNavigation/ButtonNavigation";

function Contact() {
  return (
    <section className={styles.contact}>
      <div className={styles.contactContainer}>
        <div className={styles.contactTitle}>
          <p>Des questions, des problèmes, des bugs, des améliorations ?</p>
        </div>
        <div className={styles.contactButton}>
          <Button name="Contactez-nous" link="/contact" />
        </div>
        {/* <p>Contactez-nous </p> */}
      </div>
    </section>
  );
}

export default Contact;
