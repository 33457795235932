import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import {
  Header,
  Footer,
  Home,
  Features,
  Contact,
  MentionsLegales,
  CGU,
  Patchnotes,
  BetaAndroid,
  BetaIOS,
  MailValidation,
} from "./components";

const container = document.getElementById("root");
const root = createRoot(container);

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

root.render(
  <Router>
    <ScrollToTop />
    <Header
      pagesWithoutDynamicNavbar={[
        "/contact",
        "/features",
        "/mentions-legales",
        "/cgu",
        "/patchnotes",
        "/beta-android",
        "/beta-ios",
        "/MailValidation",
      ]}
    />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/features" element={<Features />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/mentions-legales" element={<MentionsLegales />} />
      <Route path="/cgu" element={<CGU />} />
      <Route path="/patchnotes" element={<Patchnotes />} />
      <Route path="/beta-android" element={<BetaAndroid />} />
      <Route path="/beta-ios" element={<BetaIOS />} />
      <Route path="/MailValidation" element={<MailValidation />} />
      {/* 404 page */}
      {/* <Route path="*" element={<PageNotFound />} /> */}
    </Routes>
    <Footer />
  </Router>
);

serviceWorker.unregister();
