import React from "react";
import styles from "./Hero.module.css";

// Component imports
import Overlay from './Overlay/Overlay'
import Scene from './Scene/Scene'

function Hero(){
  return (
    <section className={styles.hero}>
      <Overlay />
      <Scene />
    </section>
  )
}

export default Hero;