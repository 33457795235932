import React from "react";
import styles from "./ButtonSocialMedia.module.css";
import { motion } from "framer-motion";
import useSound from "use-sound";
import clickSound from "./../../../../../assets/sounds/button.mp3";

function ButtonSocialMedia({ name, link, color }) {
  const [play] = useSound(clickSound, { volume: 0.5 });

  return (
    <motion.a
      className={styles.button}
      href={link}
      target="_blank"
      rel="noreferrer"
      style={{ background: color }}
      whileHover={{
        scale: 1.03,
        transition: { delay: 0, duration: 0.3 }, // Ajoutez une durée pour rendre le mouvement fluide
      }}
      whileTap={{
        scale: 0.97,
        transition: { delay: 0, duration: 0.3 },
      }}
      animate={{
        scale: 1,
        transition: { delay: 0, duration: 0.3 }, // Assurez-vous que l'animation de retour est également sans délai
      }}
      onMouseEnter={play}
    >
      <i className={`fi fi-brands-${name}`}></i>
      <p>{name}</p>
      <i className="fi fi-rr-up-right-from-square"></i>
    </motion.a>
  );
}

export default ButtonSocialMedia;
