import React from "react";
import styles from "./Home.module.css";

// Component imports
import Hero from "./Hero/Hero";
import Discover from "./Discover/Discover";
import Features from "./Features/Features";
import About from "./About/About";
import FAQ from "./FAQ/FAQ";
import Contact from "../others/Contact/Contact";

function Home() {
  return (
    <div className={styles.home}>
      <Hero />
      <Discover />
      <Features />
      <About />
      <FAQ />
      <Contact />
    </div>
  );
}

export default Home;
