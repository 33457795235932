import React from "react";
import styles from "./MentionsLegales.module.css"

function MentionsLegales(){
  return (
    <div className={styles.mentionsLegales}>
      <div className={styles.mentionsLegalesContainer}>
        <div className={styles.mentionsLegalesTitle}>
          <h2>Mentions Légales</h2>
        </div>
        <article className={styles.mentionsLegalesItem}>
          <p>En conformité aux dispositions  de l'article 6-III et 19 de la Loi numero 2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs du site <a href="https://libellule.app" rel="noreferrer" target="_blank">https://libellule.app</a> les informations suivantes :</p>
        </article>
        <article className={styles.mentionsLegalesItem}>
          <h3>1. Informations légales</h3>
          <div>
            <p>Propriétaire : Raphaël Tiphonet</p>
            <p>Pour contacter le propriétaire : <a href="mailto:rtiphonet@gmail.com">rtiphonet@gmail.com</a></p>
            <p>Adresse de localisation du titulaire : 16000 - Angoulême</p>
          </div>
          <div>
            <p>Le fabriquant du site suivant est : Arnaud Graciet</p>
            <p>Le directeur de la publication : Arnaud Graciet</p>
            <p>Pour contacter le décideur de la publication :  <a href="mailto:contact@arnaudgct.fr">contact@arnaudgct.fr</a></p>
          </div>
          <div>
            <p>Le concepteur et développeur est : Arnaud Graciet</p>
            <p>Pour contacter le développeur : <a href="mailto:contact@arnaudgct.fr">contact@arnaudgct.fr</a></p>
          </div>
        </article>
        <article className={styles.mentionsLegalesItem}>
          <h3>2. Introduction</h3>
          <p>Tout internaute qui se connecte et utilise le site internet : <a href="https://libellule.app" rel="noreferrer" target="_blank">https://libellule.app</a> est considéré comme usagé du site web.</p>
          <p>Le site <a href="https://libellule.app" rel="noreferrer" target="_blank">libellule.app</a> regroupe plusieurs services, en l'état, mis à disposition des usagés. Il est précisé que ceux-ci doivent rester honnête et faire preuve de bonne foi tant envers les différents usagés qu'envers le développeur du site. Le site est actualisé de façon régulière par Arnaud Graciet.</p>
          <p>Arnaud Graciet s'efforce d'offrir des informations les plus précises possibles (sous réserve de modifications apportées depuis leur mise en ligne), mais ne peuvent affirmer l'exactitude, la complétude et les informations diffusées sur son site, qu'elles soient ou non de son fait. En dépendance, l'utilisateur reconnaît utiliser ces informations données (à titre indicatif, non exhaustives et susceptibles d'évoluer) sous son unique responsabilité.</p>
        </article>
        <article className={styles.mentionsLegalesItem}>
          <h3>3. Exigence d'accessibilité</h3>
          <p>Le site internet libellule.app est habituellement accessible aux usagés H24 7/7, excepté en cas d'interruption, programmée ou non, pour les besoins de la maintenance ou en cas de force majeure. En cas de service indisponible, libellule.app s'engage à faire de son mieux pour rétablir l'accès au site et tentera alors d'informer préalablement aux utilisateurs Dates & heures de l'intervention. N'étant subordonné qu'à une obligation de moyen, libellule.app ne saurait être designé comme responsable des dommages, peu importe sa nature, résultant d'une indisponibilité du service</p>
        </article>
        <article className={styles.mentionsLegalesItem}>
          <h3>4. Propriété intellectuelle</h3>
          <p>Raphaël Tiphonet est propriétaire unique de tous les droits de propriété intellectuelle ou détient les droits et autorisations d'usage sur tous les éléments accessibles sur le site, tant sur la structure que sur les articles et textes, graphismes, logo, sons, logiciels, etc…</p>
          <p>Toute reproduction même partielle du site suivant libellule.app, affichage, édition, mis à jour totale ou partielle de l'un quelconque de ces éléments, sans tenir compte du  moyen ou la technique utilisée, est strictement interdite, excepté autorisation préalable de Raphaël Tiphonet, propriétaire du site web à l'email : <a href="mailto:rtiphonet@gmail.com">rtiphonet@gmail.com</a>, à défaut elle sera considérée de la même manière qu'une contrefaçon et passible de poursuite conformément aux dispositions des multiples articles L.335-2 et suivants du Code de Propriété Intellectuelle</p>
        </article>
        <article className={styles.mentionsLegalesItem}>
          <h3>5. Liens hypertextes</h3>
          <p>Le site web <a href="https://libellule.app" rel="noreferrer" target="_blank">libellule.app</a> contient de multiple URL cliquable vers d'autres sites web (partenaires, informations...) mis en place avec l'autorisation de Raphaël Tiphonet. Cependant, Raphaël Tiphonet n'a pas la possibilité de controler l'intégralité des élements des sites visités et décline donc toute responsabilité de ce fait concernant les risques de contenus illicites.</p>
        </article>
      </div>
    </div>
  )
}

export default MentionsLegales;