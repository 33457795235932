import React from "react";
import styles from "./Footer.module.css";
import { NavLink } from "react-router-dom";

// import components
import ButtonSocialMedia from "../inputs/buttons/ButtonSocialMedia/ButtonSocialMedia";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerContainerTop}>
          <div className={styles.applicationContainer}>
            <NavLink to="/" className={styles.applicationLogo}>
              <img src="/assets/img/libellule_logo.svg" alt="" />
              <p>Libellule</p>
            </NavLink>
            <div className={styles.applicationButtonsStore}>
              <a
                href="https://play.google.com/store/apps/details?id=com.libellule.libellule"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/img/play_store.webp" alt="" />
              </a>
              <a
                href="https://apps.apple.com/fr/app/libelluleapp/id6502843331"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/img/app_store.webp" alt="" />
              </a>
            </div>
            <div className={styles.applicationLinks}>
              <NavLink
                to="/mentions-legales"
                className={styles.applicationLink}
              >
                <p>Mentions légales</p>
              </NavLink>
              <NavLink to="/cgu" className={styles.applicationLink}>
                <p>Conditions générales d'utilisation</p>
              </NavLink>
              <NavLink to="/patchnotes" className={styles.applicationLink}>
                <p>Journal de mises à jour</p>
              </NavLink>
            </div>
          </div>
          <div className={styles.buttonsSocialMediasContainer}>
            <ButtonSocialMedia
              name="github"
              link="https://github.com/LibelluleApp/LibelluleApp"
              color="#252525"
            />
            <ButtonSocialMedia
              name="figma"
              link="https://www.figma.com/design/zZKYcCDHp4bXGOJc6M65o4/Libellule?node-id=0-1&t=v9fxudCar8s8TSYq-1"
              color="linear-gradient(93deg, #A259FF 1.68%, #1ABCFE 98.8%)"
            />
            <ButtonSocialMedia
              name="instagram"
              link="https://www.instagram.com/libelluleapp"
              color="linear-gradient(93deg, #B164FF 1.22%, #FE5BDE 98.42%)"
            />
            <ButtonSocialMedia
              name="linkedin"
              link="https://www.linkedin.com/company/libelluleapp"
              color="linear-gradient(93deg, #0C61BF 1.22%, #0C61BF 98.42%)"
            />
          </div>
        </div>
        <div className={styles.footerContainerBottom}>
          <p>
            Une application créée par{" "}
            <a href="https://rtiphonet.me/" rel="noreferrer" target="_blank">
              Raphaël Tiphonet
            </a>
             et 
            <a href="https://arnaudgct.fr/" rel="noreferrer" target="_blank">
              Arnaud Graciet
            </a>
          </p>
          <p>© 2024 - Tous droits réservés</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
