import React from "react";
import styles from "./ItemFeature.module.css";
import { motion } from "framer-motion";
import useSound from "use-sound";
import clickSound from "./../../../../../assets/sounds/features.mp3";

function ItemFeature({
  icon,
  title,
  description,
  MMI,
  isSelected,
  onClick,
  delay,
}) {
  const [play] = useSound(clickSound, { volume: 0.5 });

  return (
    <motion.div
      className={
        isSelected
          ? `${styles.itemFeature} ${styles.itemFeatureSelected}`
          : styles.itemFeature
      }
      onClick={onClick}
      initial={{ y: 20, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ ease: "easeOut", duration: 0.5, delay: delay }}
      whileHover={{
        scale: 1.03,
        transition: { delay: 0, duration: 0.3 }, // Ajoutez une durée pour rendre le mouvement fluide
      }}
      whileTap={{
        scale: 0.97,
        transition: { delay: 0, duration: 0.3 },
      }}
      animate={{
        scale: 1,
        y: 0,
        opacity: 1,
        transition: { delay: 0, duration: 0.3 }, // Assurez-vous que l'animation de retour est également sans délai
      }}
      onMouseEnter={play}
    >
      <div className={styles.itemFeatureIcon}>
        <i className={icon}></i>
      </div>
      <div className={styles.itemFeatureContent}>
        <p>
          {title}
          {MMI ? <span> (seulement pour MMI)</span> : ""}
        </p>
        <p>{description}</p>
      </div>
    </motion.div>
  );
}

export default ItemFeature;
