import React from "react";
import styles from "./PatchnotesItemContent.module.css";

function PatchnotesItemContent({ title, icon, content }) {
  return (
    <div className={styles.patchnotesItemContent}>
      <div className={styles.patchnotesItemContentTitle}>
        <h3>{title}</h3>
        {icon ? <div>{icon}</div> : null}
      </div>
      <ul className={styles.patchnotesItemList}>
        {(content || []).map((item, index) => (
          <li key={index} className={styles.listItem}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PatchnotesItemContent;
