import * as THREE from 'three'
import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'

// Component imports
import IsMobile from "./../../../../../assets/Scripts.jsx"


function Model(props) {
  const group = useRef(null)

  let isMobile = IsMobile(1400);

  useFrame((state) => {
    if(!isMobile){
      const t = state.clock.getElapsedTime()
      group.current.position.y = THREE.MathUtils.lerp(group.current.position.y, (-0.5 + Math.sin(t / 0.8)) / 14, 0.1);
    }else{
      const t = state.clock.getElapsedTime()
      group.current.rotation.y = 0
      group.current.rotation.x = 0
      group.current.position.y = THREE.MathUtils.lerp(group.current.position.y, (-0.5 + Math.sin(t / 0.8)) / 18, 0.1);
    }
  })

  const { nodes, materials } = useGLTF('/assets/3d/phone_accueil.glb')

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, -Math.PI]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Frame_Antenna_0.geometry}
              material={materials.Antenna}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Frame_Frame2_0.geometry}
              material={materials.Frame2}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Frame_Frame_0.geometry}
              material={materials.Frame}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Frame_Mic_0.geometry}
              material={materials.material}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Frame_Port_0.geometry}
              material={materials.Port}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Apple_Logo_Logo_0.geometry}
              material={materials.Logo}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Body_Bezel_0.geometry}
              material={materials.Bezel}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Body_Body_0.geometry}
              material={materials.Body}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Body_Camera_Glass_0.geometry}
              material={materials.Camera_Glass}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Body_Lens_0.geometry}
              material={materials.Lens}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Body_Material_0.geometry}
              material={materials['Material.001']}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Body_Mic_0.geometry}
              material={materials.material}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Body_Wallpaper_0.geometry}
              material={materials.Wallpaper}
              position={[0, 0, -0.046]}
              rotation={[3.138, 0, 0]} />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Button_Frame_0.geometry}
              material={materials.Frame}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera_Body_0.geometry}
              material={materials.Body}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera_Camera_Frame001_0.geometry}
              material={materials['Camera_Frame.001']}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera_Glass_0.geometry}
              material={materials.Glass}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera_Mic_0.geometry}
              material={materials.material}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera001_Black_Glass_0.geometry}
              material={materials.Black_Glass}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera001_Body_0.geometry}
              material={materials.Body}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera001_Camera_Frame_0.geometry}
              material={materials.Camera_Frame}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera001_Camera_Glass_0.geometry}
              material={materials.Camera_Glass}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera001_Flash_0.geometry}
              material={materials.Flash}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera001_Gray_Glass_0.geometry}
              material={materials.Gray_Glass}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera001_Lens_0.geometry}
              material={materials.Lens}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera001_Port_0.geometry}
              material={materials.Port}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Camera003_Material002_0.geometry}
              material={materials['Material.002']}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Circle003_Frame_0.geometry}
              material={materials.Frame}
            />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/assets/3d/iphone.glb')

export default Model
