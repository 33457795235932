import React from "react";
import styles from "./CardIdentity.module.css";

function CardIdentity({ name, job, linkedin, instagram, website, image, alt }) {
  return (
    <div className={styles.cardIdentity}>
      <div className={styles.cardIdentityImage}>
        <img src={image} alt={alt} />
      </div>
      <div className={styles.cardIdentityContent}>
        <div className={styles.cardIdentityText}>
          <p>{name}</p>
          <p>{job}</p>
        </div>
        <div className={styles.cardIdentityLinks}>
          <a href={linkedin} target="_blank" rel="noreferrer">
            <i className="fi fi-brands-linkedin"></i>
          </a>
          <a href={instagram} target="_blank" rel="noreferrer">
            <i className="fi fi-brands-instagram"></i>
          </a>
          <a href={website} target="_blank" rel="noreferrer">
            <i className="fi fi-rr-link-alt"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default CardIdentity;
