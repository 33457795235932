import React from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import styles from "./MenuItem.module.css";

function MenuItem() {
  return (
    <motion.ul className={styles.navbarNav}>
      <motion.li className={styles.navbar_item} initial={{y: 20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ease: "easeOut", duration: 0.3, delay: 0 }}>
        <NavLink className={({ isActive }) => (isActive ? styles.navbarLinkActive : styles.navbarLinkInactive)} to="/">
          Accueil
        </NavLink>
      </motion.li>
      <motion.li className={styles.navbar_item} initial={{y: 20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ease: "easeOut", duration: 0.3, delay: 0.1 }}>
        <NavLink className={({ isActive }) => (isActive ? styles.navbarLinkActive : styles.navbarLinkInactive)} to="/features">
          Fonctionnalités
        </NavLink>
      </motion.li>
      <motion.li className={styles.navbar_item} initial={{y: 20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ease: "easeOut", duration: 0.3, delay: 0.2 }}>
        <NavLink className={({ isActive }) => (isActive ? styles.navbarLinkActive : styles.navbarLinkInactive)} to="/contact">
          Contact
        </NavLink>
      </motion.li>
    </motion.ul>
  )
}

export default MenuItem;
