import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";
import HeaderMobile from "./HeaderMobile/HeaderMobile";
import HeaderDesktop from "./HeaderDesktop/HeaderDesktop";

function Header({pagesWithoutDynamicNavbar}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className={styles.header}>
      {isMobile ? <HeaderMobile /> : <HeaderDesktop pagesWithoutDynamicNavbar={pagesWithoutDynamicNavbar} />}
    </header>
  );
}

export default Header;
