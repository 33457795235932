import React from "react";
import styles from "./Arrow.module.css";
import { motion } from "framer-motion";

function Arrow({ color, name, rotate, direction, height, top, bottom, left, right, delay }) {
  return (
    <motion.div className={styles.arrowContainer} style={{top: top, bottom: bottom, left: left, right: right}} initial={{y: 20, opacity: 0}} whileInView={{y: 0, opacity: 1}} viewport={{ once: true }} transition={{ease: "easeOut", duration: 0.5, delay: delay }} >
        <div className={styles.arrowContent}>
            <div className={styles.arrowTitle} style={{ backgroundColor: color }}>
                <p>{name}</p>
            </div>
        {direction === "right" ? (
            <div className={styles.arrow} style={{ top: height, right: "-1.7rem", transform: `rotate(${rotate}deg)`  }}>
            <i
                style={{ color: color }}
                className="fi fi-sr-location-arrow"
            ></i>
            </div>
        ) : (
            <div className={styles.arrow} style={{ top: height, left: "-1.7rem", transform: `rotate(${rotate}deg)`  }}>
            <i
                style={{ color: color}}
                className="fi fi-sr-location-arrow"
            ></i>
            </div>
        )}
        </div>
    </motion.div>
  );
}

export default Arrow
