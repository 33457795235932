import React, { useRef, useState, useEffect } from "react";
import styles from "./Scene.module.css";
import { Canvas, useFrame } from "@react-three/fiber";
import { Center, Html, PerspectiveCamera } from "@react-three/drei";
import { easing } from "maath";
import { CircleGrid, Circle, Diamond } from "react-awesome-shapes";

// Component imports
import Model from "./Model/Model";
import Arrow from "./Arrow/Arrow";
import IsMobile from "./../../../../assets/Scripts.jsx";

function Scene() {
  const [position, setPosition] = useState([0.4, 0, 2.5]);

  let isMobile = IsMobile(1400);

  useEffect(() => {
    if (isMobile) {
      setPosition([0, -0.42, 0]);
    } else {
      setPosition([0.9, 0, 0.2]);
    }
  }, [isMobile]);

  return (
    <Canvas
      className={styles.heroScene}
      gl={{ preserveDrawingBuffer: true }}
      eventSource={document.getElementById("root")}
      eventPrefix="client"
    >
      <PerspectiveCamera position={position} fov={25}>
        {/* <ambientLight intensity={2.3} /> */}
        <directionalLight position={[-50, -60, 80]} intensity={3} />
        <CameraRig isMobile={isMobile}>
          <Center position={[0, 0, 0]}>
            <Model />
            <Html
              position={isMobile ? [-0.48, 0.5, 0] : [-0.5, 0.45, 0]}
              transform
              scale={isMobile ? 0.075 : 0.06}
            >
              <Arrow
                color="#BB8700"
                name="GMP"
                rotate="90"
                direction="right"
                height="20px"
                delay="0.2"
              />
            </Html>
            <Html
              position={isMobile ? [-0.5, 0.35, 0] : [-0.55, 0.3, 0]}
              transform
              scale={isMobile ? 0.075 : 0.06}
            >
              <Arrow
                color="#AC00BB"
                name="MMI"
                rotate="75"
                direction="right"
                height="16px"
                delay="0.4"
              />
            </Html>
            <Html
              position={isMobile ? [-0.48, 0.15, 0] : [-0.5, 0.14, 0]}
              transform
              scale={isMobile ? 0.075 : 0.06}
            >
              <Arrow
                color="#00B0BB"
                name="TC"
                rotate="45"
                direction="right"
                height="11px"
                delay="0.6"
              />
            </Html>
            <Html
              position={isMobile ? [0.36, -0.4, 0] : [0.45, -0.35, 0]}
              transform
              scale={isMobile ? 0.075 : 0.06}
            >
              <Arrow
                color="#BB0000"
                name="QLIO"
                rotate="-110"
                direction="left"
                height={isMobile ? "-10px" : "0px"}
                delay="0.8"
              />
            </Html>
            <Html
              position={isMobile ? [0.35, -0.55, 0] : [0.45, -0.5, 0]}
              transform
              scale={isMobile ? 0.075 : 0.06}
            >
              <Arrow
                color="#1ABB00"
                name="GEII"
                rotate={isMobile ? "-100" : "-120"}
                direction="left"
                height="0px"
                delay="1"
              />
            </Html>

            {/* Shapes */}
            <Html
              position={isMobile ? [0.32, 0.45, -0.1] : [0.4, 0.6, -0.1]}
              transform
              scale={isMobile ? 0.075 : 0.06}
            >
              <Circle
                color="linear-gradient(135deg, #6F8DC2, #0F4199)"
                size={["9rem", "9rem", "9rem", "9rem"]}
                zIndex={2}
              />
            </Html>
            <Html
              position={isMobile ? [0.3, 0.55, -0.15] : [0.35, 0.7, -0.15]}
              transform
              scale={0.05}
            >
              <Diamond
                color="linear-gradient(135deg, #0F4199, #6F8DC2)"
                size="4rem"
                zIndex={2}
              />
            </Html>
            <Html
              position={isMobile ? [-0.45, -0.52, -0.12] : [-0.45, -0.6, -0.12]}
              transform
              scale={0.05}
            >
              <CircleGrid
                position="relative"
                color="#0F4199"
                size="12rem"
                zIndex={2}
              />
            </Html>
          </Center>
          {/* <Model />
          <directionalLight position={[40, -60, 80]} intensity={4} /> */}
          {/* <directionalLight color={"#0760fb"} position={[-30, 50, 30]} intensity={6} /> */}
        </CameraRig>
      </PerspectiveCamera>
    </Canvas>
  );
}

// function Backdrop() {
//     const shadows = useRef()
//     useFrame((state, delta) => easing.dampC(shadows.current.getMesh().material.color, state.color, 0.25, delta))
//     return (
//       <AccumulativeShadows ref={shadows} temporal frames={60} alphaTest={0.60} scale={5} rotation={[Math.PI / 2, 0, 0]} position={[0, 0, -0.1]}>
//         <RandomizedLight amount={2} radius={9} intensity={2} ambient={90} position={[5, 5, -3]} />
//         <RandomizedLight amount={2} radius={9} intensity={1} ambient={20} position={[-5, 5, -3]} />
//       </AccumulativeShadows>
//     )
//   }

function CameraRig({ children, isMobile }) {
  const group = useRef();
  useFrame((state, delta) => {
    easing.damp3(state.camera.position, [0, 0, 1.5], 0.5, delta);
    easing.dampE(
      group.current.rotation,
      [state.pointer.y / 4, (isMobile ? 0 : -0.4) + -state.pointer.x / 2, 0],
      0.35,
      delta
    );
  });
  return <group ref={group}>{children}</group>;
}

export default Scene;
