import React from "react";
import styles from "./Features.module.css";

// Import components
import Hero from "./Hero/Hero";
import Video from "./Video/Video";
// import Dashboard from "./Dashboard/Dashboard";
import Contact from "./../others/Contact/Contact";

function Features() {
  return (
    <div className={styles.features}>
      <Hero />
      <Video />
      {/* <Dashboard /> */}
      <Contact />
    </div>
  );
}

export default Features;
