import React from "react";
import styles from "./Discover.module.css";

// import components
import Item from "./Item/Item";

function Discover() {
  return (
    <section className={styles.discover}>
      <div className={styles.discoverContainer}>
        <div className={styles.titlesContainer}>
          <p>Une <span>seule application</span> pour répondre à <span>vos besoins</span></p>

          <p>Arrêtons de naviguer entre des applications tierces comme <span>TimeCalendar</span> ou <span>UnivPoitiers</span></p>
        </div>
        <div className={styles.itemsContainer}>
          <Item title={"En constante évolution"} description={"Vos feedbacks sont essentiels pour nous aider à régler les potentiels bugs et améliorer l’application."} image={"/assets/img/working.svg"} alt={"Illustration de trois personnes assises autour d'un bureau en train de travailler"} />

          <Item title={"Une interface ergonomique"} description={"Nous avons passé du temps à réfléchir et concevoir une interface moderne et épurée."} image={"/assets/img/thinking.svg"} alt={"Illustration d'une personne en train de réfléchir"} />

          <Item title={"Bénévoles et indépendants"} description={"Ce projet consolide nos compétences en web development et web design en toute indépendance de l’université."} image={"/assets/img/working.svg"} alt={"Illustration d'une personne qui est en train de coder sur un ordinateur portable"} />

          <div className={styles.shapeTriangle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="73" viewBox="0 0 70 73" fill="none">
              <path d="M56.2977 1.65991C63.1385 -0.925464 70.2128 4.86412 69.0314 12.0812L60.4594 64.4453C59.278 71.6623 50.7269 74.894 45.0675 70.2623L4.00482 36.6568C-1.6546 32.0251 -0.177809 23.0038 6.66305 20.4184L56.2977 1.65991Z" stroke="var(--blue-900-80)" />
            </svg>
          </div>

          <div className={styles.shapeCircle}></div>
        </div>
      </div>

    </section>
  )
}

export default Discover;