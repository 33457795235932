import React from "react";
import styles from "./Overlay.module.css";

// Import components
import ItemFeature from "./ItemFeature/ItemFeature";

function Overlay({ selected, setSelected }) {
  const handleSelect = (index) => {
    setSelected(index);
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.containerFeatures}>
        <ItemFeature
          icon="fi fi-rr-calendar-lines"
          title="Emploi du temps"
          description="Ne cherchez plus vos horaires à la va-vite. Accès rapide à votre prochain cours."
          MMI={false}
          isSelected={selected === 0}
          onClick={() => handleSelect(0)}
          delay="0.4"
        />

        <ItemFeature
          icon="fi fi-rr-check-circle"
          title="Agenda"
          description="Liste de vos devoirs à faire et vos évaluations en commun avec votre groupe de classe."
          MMI={false}
          isSelected={selected === 1}
          onClick={() => handleSelect(1)}
          delay="0.6"
        />

        <ItemFeature
          icon="fi fi-rr-envelope"
          title="Mails"
          description="Ne manquez plus un mail avec la possibilité de les lire directement dans l’application."
          MMI={false}
          isSelected={selected === 2}
          onClick={() => handleSelect(2)}
          delay="0.8"
        />

        <ItemFeature
          icon="fi fi-rr-restaurant"
          title="Restauration"
          description="Menu du jour du restaurant universitaire Crousty situé en face de l’IUT d’Angoulême."
          MMI={false}
          isSelected={selected === 3}
          onClick={() => handleSelect(3)}
          delay="1"
        />

        {/* <ItemFeature icon="fi fi-rr-book-alt" title="Scolarité" description="Soyez informé de votre nombre d’absences et de la moyenne de vos compétences." MMI={true} isSelected={selected === 4} onClick={() => handleSelect(4)} delay="1.2" /> */}

        {/* <ItemFeature icon="fi fi-rr-video-camera" title="Réservation de matériels" description="Faites votre panier du matériel nécessaire pour votre prochain projet audiovisuel." MMI={true} isSelected={selected === 5} onClick={() => handleSelect(5)} delay="1.4" /> */}
      </div>
    </div>
  );
}

export default Overlay;
