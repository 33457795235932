import React, {useState, useEffect} from "react";
import styles from "./Contact.module.css";

// Import components
import Form from "./Form/Form";
import Alert from "./Alert/Alert";

function Contact() {
  const [state, setState] = useState(null);

  useEffect(() => {
    if (state !== null) {
      const timer = setTimeout(() => {
        setState(null);
      }, 5500); // Remettre à null après 5 secondes et les 0.5 secondes de transition de fin d'animation du pop-up (timer défini dans le composant Alert)

      return () => clearTimeout(timer); // Nettoyage du timer au démontage du composant
    }
  }, [state]);

  return (
    <div className={styles.contact}>
      <div className={styles.contactContainer}>
        <div className={styles.contactText}>
          <div className={styles.contactTitle}>
            <h2>Nous contacter</h2>
          </div>
          <div className={styles.contactDescription}>
          <p>Vous avez rencontré un bug ou des problèmes. Vous souhaitez nous faire part d’améliorations ou autre demande.</p>
          <p>Vous pouvez aussi nous contacter directement sur nos réseaux sociaux ou via l’adresse mail ci-dessous.</p>
          </div>
          <div className={styles.contactLink}>
            <a href="mailto:support@libellule.app">support@libellule.app</a>
          </div>
          <div className={styles.shapeTriangleTop}>
            <svg xmlns="http://www.w3.org/2000/svg" width="135" height="138" viewBox="0 0 135 138" fill="none">
                <path d="M24.8827 12.5889C27.293 1.69013 40.5977 -2.51232 48.8311 5.02449L129.753 79.0998C137.987 86.6366 134.974 100.26 124.33 103.622L19.7177 136.665C9.07395 140.027 -1.21784 130.606 1.19252 119.707L24.8827 12.5889Z" stroke="var(--blue-900-80)"/>
            </svg>
          </div>
          <div className={styles.shapeCircleTop}></div>
          <div className={styles.shapeSquareTop}></div>
          <div className={styles.shapeCircleBottom}></div>
        </div>
        <div className={styles.contactForm}>
          <h3>Formulaire de contact</h3>
          <Form state={state} setState={setState} />
        </div>
      </div>
      {state === "SUCCESS" ? <Alert severity="success" /> : state === "ERROR" ? <Alert severity="error" /> : null}
    </div>
  );
}

export default Contact;
