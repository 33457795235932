import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Accordion.module.css";
import { Accordion, AccordionTab } from "primereact/accordion";

function AccordionElement() {
  return (
    <div className={styles.accordion}>
      <Accordion className={styles.accordionContainer}>
        {/* <AccordionTab
          header="Je ne vois pas l'application sur le Play Store, est-ce normal ?"
          headerClassName={styles.accordionHeader}
          contentClassName={styles.accordionContent}
        >
          <div className={styles.accordionContentItem}>
            <p>
              L'application Libellule est actuellement en{" "}
              <span>phase de test</span>. Elle est donc en bêta fermée sur le
              Play Store. Cependant, vous pouvez{" "}
              <span>rejoindre le programme de test</span> et télécharger
              l'application en renseignant votre adresse mail étudiante en
              cliquant <NavLink to="/beta-android">ici</NavLink>.{" "}
            </p>

            <p>
              Un mail vous sera envoyé vous expliquant les étapes à réaliser
              (rien de bien compliqué, rassurez-vous 😊)
            </p>
          </div>
        </AccordionTab>
        <AccordionTab
          header="Je suis sur IOS, comment installer l'application ?"
          headerClassName={styles.accordionHeader}
          contentClassName={styles.accordionContent}
        >
          <div className={styles.accordionContentItem}>
            <p>
              Sur IOS, l'application Libellule est téléchargeable sur l'App
              Store dès maintenant en cliquant{" "}
              <a href="" target="_blank" rel="noreferrer">
                ici
              </a>
              .
            </p>

            <p>
              Cependant, pour qu'on vous envoie vos identifiants nécessaires
              pour pouvoir vous connecter, vous devez nous indiquer votre{" "}
              <span>adresse mail étudiante</span> en cliquant{" "}
              <NavLink to="/beta-ios">ici</NavLink>.
            </p>
          </div>
        </AccordionTab> */}
        {/* <AccordionTab
          header="Comment se créer un compte et accéder à l’application ?"
          headerClassName={styles.accordionHeader}
          contentClassName={styles.accordionContent}
        >
          <div className={styles.accordionContentItem}>
            <p>
              Pour accéder à Libellule, il est nécessaire que vous soyez{" "}
              <span>étudiant à l’IUT d’Angoulême</span>.
            </p>
            <div>
              <li>Pour les étudiants de MMI :</li>
              <p>
                Votre compte est déjà créé et vos identifiants vous ont été
                envoyés par mail. Le mot de passe qui vous ai attribué est
                temporaire et vous sera demandé de le changer lors de votre
                première connexion.
              </p>
            </div>
            <div>
              <li>
                Pour les étudiants des autres départements de l’IUT d’Angoulême
              </li>
              <p>
                L'inscription se fait directement sur l'application en
                renseignant son nom, prénom, mot de passe, groupe de classe et
                son adresse mail étudiante qu'il vous sera demandée de
                confirmer.
              </p>
            </div>

            <p>
              Une fois que <span>vous avez confirmée votre inscription</span> en
              cliquant sur le lien du mail que vous recevrez dans votre boite de
              récpetion, vous pourrez vous connecter sur l’application en
              utilisant votre adresse mail et le mot de passe que vous avez
              choisi.
            </p>
          </div>
        </AccordionTab> */}
        {/* <AccordionTab
          header="Comment fonctionne l’agenda ?"
          headerClassName={styles.accordionHeader}
          contentClassName={styles.accordionContent}
        >
          <div className={styles.accordionContentItem}>
            <p>
              Avant Libellule, chaque étudiant devait inscrire les devoirs et
              les évaluations dans son propre agenda, qu'il soit papier ou
              numérique. Nous proposons maintenant qu'
              <span>un seul étudiant</span> par groupe de classe se charge de
              noter les devoirs et les évaluations pour{" "}
              <span>l'ensemble de ses camarades</span>.
            </p>
            <p>
              Le <span>principal avantage</span> est pour les étudiants qui
              peuvent être absents lors d'un cours où un enseignant annonce une
              évaluation. Grâce à Libellule, ces étudiants seront informés des
              évaluations prévues.
            </p>
          </div>
        </AccordionTab> */}
        <AccordionTab
          header="Je suis en MMI, je ne peux pas créer de compte, pourquoi ?"
          headerClassName={styles.accordionHeader}
          contentClassName={styles.accordionContent}
        >
          <div className={styles.accordionContentItem}>
            <p>
              Les étudiants de MMI ne peuvent pas créer de compte car ils ont un
              compte déjà créé. Pour <span>recevoir vos identifiants</span>,
              veuillez contacter l'équipe de Libellule depuis la page{" "}
              <NavLink to="/contact">contact</NavLink> ou directement sur notre{" "}
              <a
                href="https://www.instagram.com/libelluleapp/"
                target="_blank"
                rel="noreferrer"
              >
                compte Instagram
              </a>{" "}
              en renseignant votre adresse mail étudiante.
            </p>
          </div>
        </AccordionTab>
        {/* <AccordionTab
          header="Je suis en GMP, je ne peux pas créer de compte, pourquoi ?"
          headerClassName={styles.accordionHeader}
          contentClassName={styles.accordionContent}
        >
          <div className={styles.accordionContentItem}>
            <p>
              L'intégration des étudiants de GMP est en cours et arrivera très
              prochainement. Pour être informé de sa mise en place, on vous
              invite à nous suivre sur notre{" "}
              <a
                href="https://www.instagram.com/libelluleapp/"
                target="_blank"
                rel="noreferrer"
              >
                compte Instagram
              </a>
              .
            </p>
          </div>
        </AccordionTab> */}
        <AccordionTab
          header="Comment fonctionne l’agenda ?"
          headerClassName={styles.accordionHeader}
          contentClassName={styles.accordionContent}
        >
          <div className={styles.accordionContentItem}>
            <p>
              Avant Libellule, chaque étudiant devait inscrire les devoirs et
              les évaluations dans son propre agenda, qu'il soit papier ou
              numérique. Nous proposons maintenant qu'
              <span>un seul étudiant</span> par groupe de classe se charge de
              noter les devoirs et les évaluations pour{" "}
              <span>l'ensemble de ses camarades</span>.
            </p>
            <p>
              Le <span>principal avantage</span> est pour les étudiants qui
              peuvent être absents lors d'un cours où un enseignant annonce une
              évaluation. Grâce à Libellule, ces étudiants seront informés des
              évaluations prévues.
            </p>
          </div>
        </AccordionTab>
        <AccordionTab
          header="Comment est choisi le responsable de l'agenda ?"
          headerClassName={styles.accordionHeader}
          contentClassName={styles.accordionContent}
        >
          <div className={styles.accordionContentItem}>
            <p>
              Au début de l'année, le <span>premier étudiant</span> à s'inscrire
              est désigné comme responsable de l'agenda de son groupe de classe.
              Cependant, cet étudiant peut <span>déléguer ce rôle</span> à un
              autre volontaire via son profil.
            </p>
            <p>
              En effet, étant donné que le responsable peut déléguer son rôle à
              tout moment, l'objectif est que chaque groupe de classe choisisse
              son responsable d'agenda en concertation. Cela permet une{" "}
              <span>organisation personnalisée</span>, comme par exemple,
              changer de responsable chaque semaine.
            </p>
          </div>
        </AccordionTab>
        {/* <AccordionTab header="Comment identifier le responsable de l'agenda ou le délégué de mon groupe de classe ?" headerClassName={styles.accordionHeader} contentClassName={styles.accordionContent}>
                    <div className={styles.accordionContentItem}>
                        <p>Sur l'onglet "Vue d'ensemble", qui constitue la page d'accueil, une fonctionnalité intitulée "Ma Promo" vous permet de visualiser <span>tous les étudiants de votre groupe de classe</span>. Cette section fournit les <span>informations de contact</span> de chaque membre ainsi que des badges indiquant leurs <span>rôles potentiels</span>.</p>
                        <p><i class="fi fi-sr-graduation-cap"></i> : <span>Délégué</span></p>
                        <p><i class="fi fi-sr-check-circle"></i> : <span>Responsable de l'agenda</span></p>
                        <p><i class="fi fi-sr-notebook-alt"></i> : <span>Responsable du cahier</span> (seulement pour MMI)</p>
                    </div>
                </AccordionTab> */}
        <AccordionTab
          header="En tant que responsable de l’agenda, que devez-vous faire ?"
          headerClassName={styles.accordionHeader}
          contentClassName={styles.accordionContent}
        >
          <div className={styles.accordionContentItem}>
            <p>
              Le responsable de l’agenda a la responsabilité d’
              <span>ajouter</span> et de <span>maintenir à jour</span> l’agenda
              de son groupe de classe.
            </p>
            <li>Pour ajouter un évènement dans l’agenda</li>
            <p>
              En bas de l’onglet “Agenda”, vous avez un bouton “+”. Vous devez
              alors renseigner la <span>date de l’évènement</span>, la{" "}
              <span>matière</span>, le <span>type de l’évènement</span> (devoir
              à rendre ou évaluation), le <span>titre</span> et une{" "}
              <span>potentielle description</span>. En cliquant sur “Valider”,
              l’évènement s’ajoute à la date indiquée et est visible par tous
              les étudiants de votre groupe de classe.
            </p>
            {/* <p>
              *Pour le <span>renseignement de la matière</span>, si vous n’avez
              aucune matière disponible, c’est normal. En effet, c’est à chaque
              responsable de les <span>ajouter</span>. Pour ce faire, soit vous
              taper le nom de votre matière (ex : Anglais) et en cliquant sur le
              nom, ça l’ajoute dans la base de données. Vous pouvez retrouver
              toutes les matières que vous avez créé en cliquant sur l’icône{" "}
              <i class="fi fi-sr-settings-sliders"></i>. Cet onglet vous permet
              d’<span>ajouter</span>, de <span>modifier</span> et de{" "}
              <span>supprimer</span> des matières.
            </p> */}
            <li>Pour modifier/supprimer un évènement dans l’agenda</li>
            <p>
              En cliquant sur un évènement, vous avez un onglet qui s’ouvre avec
              les différents détails de celui-ci. En bas de l’onglet, vous avez
              deux boutons permettant de supprimer ou de modifier l’évènement en
              cliquant dessus.
            </p>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
}

export default AccordionElement;
