import React from "react";
import styles from "./Title.module.css";
import { motion } from "framer-motion";

function Title({ delay }) {
    return (
      <motion.div className={styles.title} initial={{y: 20, opacity: 0}} whileInView={{y: 0, opacity: 1}} viewport={{ once: true }} transition={{ease: "easeOut", duration: 0.5, delay: delay }}>
          <h2>La seule application <span>qu’il vous faut</span> pour vos études</h2>
          <p>Découvrez les fonctionnalités qui vont vous faire adorer Libellule.</p>
      </motion.div>
    );
  }
  
  export default Title;