import React, { useRef, useEffect, useState } from "react";
import styles from "./Scene.module.css";
import { Canvas, useFrame } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import { easing } from "maath";

// Component imports
import IsMobile from "../../../../assets/Scripts.jsx";
import Model from "./Model/Model.jsx";

function Scene({ selected }) {
  const [position, setPosition] = useState([0.4, 0, 2.5]);

  let isMobile = IsMobile(1024);

  useEffect(() => {
    if (isMobile) {
      setPosition([0, 0, 0]);
    } else {
      setPosition([0, 0, -0.1]);
    }
  }, [isMobile]);

  return (
    <Canvas
      style={{ width: isMobile ? "100%" : "50%" }}
      className={styles.heroScene}
      gl={{ preserveDrawingBuffer: true }}
      eventSource={document.getElementById("root")}
      eventPrefix="client"
    >
      <PerspectiveCamera position={position} fov={25}>
        <directionalLight position={[80, 50, 80]} intensity={3} />
        <CameraRig isMobile={isMobile}>
          <Model selected={selected} />
        </CameraRig>
      </PerspectiveCamera>
    </Canvas>
  );
}

function CameraRig({ children, isMobile }) {
  const group = useRef();
  useFrame((state, delta) => {
    easing.damp3(state.camera.position, [0, 0, 0.8], 0.5, delta);
    if (!isMobile) {
      easing.dampE(
        group.current.rotation,
        [0.04 + state.pointer.y / 14, -0.05 + state.pointer.x / 14, 0.05],
        0.35,
        delta
      );
    } else {
      easing.dampE(group.current.rotation, [0, 0, 0], 0.35, delta);
    }
  });
  return <group ref={group}>{children}</group>;
}

export default Scene;
