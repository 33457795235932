import React, { useEffect, useState } from "react";
import styles from "./HeaderMobile.module.css";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { Spiral as Hamburger } from 'hamburger-react'
import MenuItem from "./MenuItem/MenuItem";
import useSound from 'use-sound';
import clickSound from "./../../../../assets/sounds/nav.mp3";

function HeaderMobile() {
  const [isOpen, toggleOpen] = useState(false);

  // Fermer le menu quand on clique en dehors du bouton burger
  useEffect(() => {

    const closeMenu = (e) => {
      if (e.target.className !== 'hamburger-react') {
        toggleOpen(false)
      }
      
    };

    document.addEventListener("scroll", closeMenu);
    document.addEventListener("click", closeMenu);
  });

  // Animation de l'arrière-plan du header
  const variants = {
    open: {height: "12rem", transition: {type: "spring", stiffness: 200, damping: 25} },
    closed: {height: "3.75rem", transition: {type: "spring", stiffness: 200, damping: 25} },
  }

  const [play] = useSound(clickSound, { volume: 0.75 });

  return (
    <nav className={styles.navbar}>
      <div className={styles.container}>
        <NavLink className={styles.navbarBrand} to="/">
          <img 
            src="/assets/img/libellule_logo.svg"
            alt=""
          />
          <h1>Libellule</h1>
        </NavLink>
        <div onClick={play}>
          <Hamburger label="Afficher le menu" color="#0760fb" size={27} toggled={isOpen} toggle={toggleOpen} rounded hideOutline={true} onClick={play} />
        </div>
      </div>

      {isOpen && <MenuItem />}

      <motion.div 
      animate={isOpen ? "open" : "closed"}
      variants={variants}
      className={styles.background}>
      </motion.div>
      
    </nav>
  );
}

export default HeaderMobile;
