import React, { useState } from "react";
import styles from "./BetaIOS.module.css";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import useSound from "use-sound";
import clickSound from "./../../assets/sounds/button_nav.mp3";

function BetaIOS() {
  const [play] = useSound(clickSound, { volume: 0.5 });

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Expression régulière pour le format de l'email
    const emailPattern = /^[a-zA-Z0-9._%+-]+@etu\.univ-poitiers\.fr$/;

    if (!emailPattern.test(email)) {
      setError(
        "Veuillez entrer une adresse e-mail valide au format @etu.univ-poitiers.fr"
      );
      setSuccess("");
    } else {
      setError("");
      // Envoyer les données du formulaire
      try {
        const response = await fetch(
          "https://dev.libellule.app/user/firstlogin",
          {
            method: "POST",
            body: JSON.stringify({
              email_edu: email,
            }),
            headers: { "Content-Type": "application/json" },
          }
        );
        if (response.status === 400) {
          setError(
            "Les identifiants ont déjà été envoyés. En cas de problème, veuillez nous contacter par mail."
          );
          setSuccess("");
          throw new Error(
            "Vous avez déjà rejoint la bêta fermée de Libellule."
          );
        }

        if (response.status === 404) {
          setError(
            "L'adresse mail renseignée n'existe pas. Si vous pensez qu'il s'agit d'une erreur, veuillez nous contacter par mail."
          );
          setSuccess("");
          throw new Error(
            "L'adresse mail renseignée n'est pas une adresse mail étudiante de l'Université de Poitiers."
          );
        }

        if (!response.ok) {
          throw new Error("Erreur lors de l'envoi des données");
        } else {
          setSuccess(
            "Parfait ! Un mail vous a été envoyé avec vos identifiants pour vous connecter à Libellule."
          );
        }

        const data = await response.json();
      } catch (error) {
        console.error("Erreur de requête :", error);

        setSuccess("");
      }
    }
  };

  return (
    <div className={styles.betaios}>
      <div className={styles.betaiosContainer}>
        <div className={styles.betaiosText}>
          <div className={styles.betaiosTitle}>
            <h2>Installer Libellule sur IOS</h2>
          </div>
          <div className={styles.betaiosDescription}>
            <p>
              Contrairement aux utilisateurs d'Android, Libellule est
              téléchargeable dès maintenant sur l'App Store en cliquant{" "}
              <a
                href="https://apps.apple.com/fr/app/libelluleapp/id6502843331"
                target="_blank"
                className={styles.betaiosDescriptionLink}
              >
                ici
              </a>
              . Cependant pour qu'on vous envoie vos identifiants, il est
              nécessaire de nous indiquer votre adresse mail étudiante
              ci-dessous afin que vous puissiez vous connecter à l'application.
            </p>
            <p>
              Si vous rencontrez des problèmes ou que vous ne recevez pas le
              mail, vous pouvez nous contactez depuis la page{" "}
              <NavLink className={styles.betaiosDescriptionLink} to="/contact">
                contact
              </NavLink>
            </p>
          </div>
        </div>
        <div className={styles.betaiosFormContainer}>
          <p className={styles.betaiosFormDescription}>
            En renseignant votre <span>adresse mail étudiante</span> ci-dessous,
            vous recevrez un mail avec <span>vos identifiants</span> pour vous
            connecter à Libellule.
          </p>
          <div className={styles.betaiosForm}>
            <form onSubmit={handleSubmit} className={styles.betaiosFormContent}>
              <input
                className={styles.closebetaFormInput}
                type="email"
                name="email"
                placeholder="Entrer l'adresse mail étudiante"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <motion.div
                className={styles.formSendButton}
                whileHover={{
                  scale: 1.03,
                  transition: { delay: 0, duration: 0.3 },
                }}
                whileTap={{
                  scale: 0.97,
                  transition: { delay: 0, duration: 0.3 },
                }}
                animate={{
                  scale: 1,
                  transition: { delay: 0, duration: 0.3 },
                }}
                onMouseEnter={play}
              >
                <input
                  type="submit"
                  className={styles.closebetaFormButton}
                  value="Recevoir mes identifiants"
                />
              </motion.div>
            </form>
            {error && (
              <div className={styles.error}>
                <p>{error}</p>
              </div>
            )}
            {success && (
              <div className={styles.success}>
                <p>{success}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BetaIOS;
