import React from "react";
import styles from "./Features.module.css";

// import components
import TaskItem from "./TaskItem/TaskItem";
import Button from "../../others/inputs/buttons/ButtonNavigation/ButtonNavigation";

function Features() {
  return (
    <section className={styles.features}>
      <div className={styles.featuresContainer}>
        <div className={styles.featuresTextContainer}>
          <div className={styles.featuresTitle}>
            <p>
              Des fonctionnalités imaginées et développées{" "}
              <span>pour vous</span>
            </p>
          </div>
          <div className={styles.featuresItems}>
            <TaskItem name="Emploi du temps" />
            <TaskItem name="Agenda collaboratif avec votre groupe de classe" />
            <TaskItem name="Lecture de vos mails" />
            <TaskItem name="Menu du RU Crousty" />
            {/* <TaskItem name="Accès pour les enseignants et l’administration" /> */}
          </div>
          <div className={styles.featuresButton}>
            <Button name="Voir toutes les fonctionnalités" link="/features" />
          </div>
        </div>
        <div className={styles.featuresScreenshots}>
          <img
            className={styles.featuresScreenshotsMails}
            src="/assets/img/screenshots/Mails.png"
            alt=""
          />
          <img
            className={styles.featuresScreenshotsAgenda}
            src="/assets/img/screenshots/Agenda.png"
            alt=""
          />
        </div>
      </div>
      <div className={styles.background}></div>
    </section>
  );
}

export default Features;
