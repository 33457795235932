import React from "react";
import styles from "./Item.module.css";

function Item({title, description, image, alt}) {
    return(
        <div className={styles.itemContainer}>
            <div className={styles.itemImage}>
                <img src={image} alt={alt} />
            </div>
            <div className={styles.itemContent}>
                <div className={styles.itemTitle}>
                    <p>{title}</p>
                </div>
                <div className={styles.itemDescription}>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default Item;