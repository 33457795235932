import React, { useRef } from "react";
import styles from "./Form.module.css";
import { motion } from "framer-motion";
import useSound from "use-sound";
import clickSound from "./../../../assets/sounds/button_nav.mp3";

function Form() {
  const [play] = useSound(clickSound, { volume: 0.5 });
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const data = {
      firstName: formData.get("user_firstname"),
      lastName: formData.get("user_lastname"),
      email: formData.get("user_email"),
      subject: formData.get("user_subject"),
      message: formData.get("message"),
    };

    try {
      const response = await fetch(
        "https://dev.libellule.app/website/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Headers": "Content-Type",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        alert("Email sent successfully");
      } else {
        alert("Error sending email");
      }
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  return (
    <form className={styles.form} ref={form} onSubmit={sendEmail}>
      <div className={styles.formContent}>
        <div className={styles.formContentName}>
          <input
            type="text"
            name="user_firstname"
            placeholder="Votre prénom"
            required
          />
          <input
            type="text"
            name="user_lastname"
            placeholder="Votre nom"
            required
          />
        </div>
        <input
          type="email"
          name="user_email"
          placeholder="Votre email"
          required
        />
        <input
          type="text"
          name="user_subject"
          placeholder="Votre sujet"
          required
        />
        <textarea name="message" placeholder="Votre message" required />
      </div>
      <div className={styles.formSend}>
        <motion.div
          className={styles.formSendButton}
          whileHover={{
            scale: 1.03,
            transition: { delay: 0, duration: 0.3 },
          }}
          whileTap={{
            scale: 0.97,
            transition: { delay: 0, duration: 0.3 },
          }}
          animate={{
            scale: 1,
            transition: { delay: 0, duration: 0.3 },
          }}
          onMouseEnter={play}
        >
          <input type="submit" value="Envoyer" />
        </motion.div>
        <p>
          En envoyant ce mail, vous ne pourrez plus le modifier et vous recevrez
          une copie par mail.
        </p>
      </div>
    </form>
  );
}

export default Form;
