import React from "react";
import styles from "./FAQ.module.css";

// Import components
import AccordionElement from "./Accordion/Accordion";

function FAQ() {
    return(
        <section className={styles.faq}>
            <div className={styles.faqContainer}>
                <p>FAQ</p>
                <AccordionElement />
            </div>
            
        </section>
    )
}

export default FAQ;