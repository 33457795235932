import React, {useState} from "react";
import styles from "./Hero.module.css";
import { motion } from "framer-motion";

// Import components
import Scene from "./Scene/Scene";
import Overlay from "./Overlay/Overlay";
import Title from "./Title/Title";

function Hero() {
    const [selected, setSelected] = useState(0);

    return (
      <div className={styles.hero}>
        <div className={styles.heroContent}>
          <Title delay="0.2" />
          <div className={styles.heroContentFeatures}>
            <Scene selected={selected} />
            <Overlay selected={selected} setSelected={setSelected} />
            <div className={styles.shapeTriangleTop}>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2934 4.79256C11.0733 3.51643 12.9267 3.51643 13.7066 4.79256L21.1404 16.9571C21.9549 18.2898 20.9957 20 19.4339 20H4.56611C3.00425 20 2.04512 18.2898 2.85955 16.9571L10.2934 4.79256Z" stroke="var(--blue-900-80)" stroke-width="0.3"/>
              </svg>
              </div>
            <div className={styles.shapeCircleTop}></div>
            <div className={styles.shapeSquareTop}></div>
            <div className={styles.shapeCircleBottom}></div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Hero;
  