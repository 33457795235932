import React, { useEffect, useState } from "react";
import styles from "./Alert.module.css";
import { motion, AnimatePresence } from "framer-motion";
import useSound from 'use-sound';
import successSound from "./../../../assets/sounds/send_message.mp3";
import errorSound from "./../../../assets/sounds/error.mp3";

function Alert({ severity }) {
    const [playSuccess] = useSound(successSound, { volume: 0.75 });
    const [playError] = useSound(errorSound, { volume: 0.75 });

    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (isVisible) {
            if (severity === "success") {
                playSuccess();
            } else if (severity === "error") {
                playError();
            }
        }
    }, [isVisible, severity, playSuccess, playError]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 5000); 

        return () => clearTimeout(timer); // Nettoyage du timer
    }, []);

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div 
                    className={severity === "success" ? `${styles.alert} ${styles.alertSuccess}` : severity === "error" ? `${styles.alert} ${styles.alertError}` : null}
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                    exit={{ opacity: 0, x: 100 }}
                >
                    {severity === "success" ? (
                        <div className={styles.alertContent}>
                            <i className="fi fi-sr-checkbox"></i>
                            <p>Votre message a bien été envoyé.</p>
                        </div>
                    ) : severity === "error" ? (
                        <div className={styles.alertContent}>
                            <i className="fi fi-sr-exclamation"></i>
                            <p>Une erreur est survenue.</p>
                        </div>
                    ) : null}
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default Alert;
